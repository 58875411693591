import { useStaticQuery, graphql } from 'gatsby';

const useProgrammesData = () => {
    const programmesData = useStaticQuery(graphql`
        query programmesData {
            wordpress {
                programmes(first: 10000) {
                    nodes {
                        title
                        slug
                        content
                        featuredImage {
                            node {
                                altText
                                sourceUrl
                                imageFile {
                                    childImageSharp {
                                        fluid(maxWidth: 1140) {
                                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                        }
                                    }
                                }
                            }
                        }
                        ACFProgrammeFields {
                            description
                        }
                    }
                }
            }
        }
    `);

    return programmesData.wordpress.programmes.nodes;
};

export default useProgrammesData;
