import React, { useContext, useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { Link } from 'gatsby';

import ThemeContext from "../../context/context.theme";
import Button from '../../components/component.button';
import { navigate } from "gatsby"

const AccessForm = ({ programme }) => {
    const theme = useContext(ThemeContext);
    const defaultFormDetails = {
        name: '',
        email: '',
    };
    const [formDetails, setFormDetails] = useState(defaultFormDetails);
    
    const handleInputChange = event => {
        const { target } = event;
        const { value, name } = target;
        
        setFormDetails({
            ...formDetails,
            [name]: value,
        });
    };
    
    const handleSubmit = async event => {
        event.preventDefault();
        addToMailchimp(formDetails.email, { 'NAME': formDetails.name, 'PAGEID': programme.title })
            .then(data => {
                setFormDetails({ name: '', email: '' });
                navigate(`/${programme.slug}`, { state: { success: true }})
            });
    };
    
    return (
        <section>
            <div>
                <form onSubmit={handleSubmit}>
    
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="c-input-holder">
                                <input
                                    className={formDetails.name.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formDetails.name}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label className="c-input-holder__label" htmlFor="name">Name</label>
                            </div>
                        </div>
    
                        <div className="col-sm-6">
                            <div className="c-input-holder">
                                <input
                                    className={formDetails.email.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                    type="text"
                                    id="email"
                                    name="email"
                                    value={formDetails.email}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label className="c-input-holder__label" htmlFor="email">Email</label>
                            </div>
                        </div>
                    </div>
    
                    <div className="row">
                        <div className="col-sm-6 xs-mb-10">
                            <p className="u-text--extra-small xs-pb-0">Sign up to our Interactive Programme to gain access.</p>
                            <Link className="u-text--extra-small o-link--underlined" to="/terms-and-conditions/">View our privacy policy</Link>
                        </div>
        
                        <div className="col-sm-6">
                            <div className="c-programme__submit">
                                <Button text="Access Programme" />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default AccessForm;
